import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute} from "@angular/router";
import {TokenService} from "./services/token.service";
const {version} = require('../../package.json');

@Component({
  selector: 'fdr-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public currenTheme = '';
  public isVisible = true;
  public version = version;


  constructor(private translateService: TranslateService, private route: ActivatedRoute, private tokenService: TokenService) {
    // this.tokenService.driverAppId.next('747174e2-fca7-42ba-b9ac-262bb9b137f2');
    this.tokenService.driverAppId.next(
      new URLSearchParams(
        new URL(
          location.toString().replace('#/', '')).search)
        .get('application_id'));
    this.tokenService.fireBaseToken$.next(
      new URLSearchParams(
        new URL(
          location.toString().replace('#/', '')).search)
        .get('fmc'));
    translateService.addLangs(['ua']);
    translateService.setDefaultLang('ua');
  }

  ngOnInit(): void {
    console.log('version', this.version);
  }

  private setTheme() {
    const href = location.href;
    if (href.includes('light-driver')) {
      this.currenTheme = 'light-driver';
    } else if(href.includes('dark-driver')) {
      this.currenTheme = 'dark-driver';
    } else if(href.includes('light-passenger')) {
      this.currenTheme = 'light-driver';
    } else if(href.includes('dark-passenger')) {
      this.currenTheme = 'dark-driver';
    } else if(href.includes('light-web')) {
      this.currenTheme = 'light-driver';
    } else if (href.includes('dark-web')) {
      this.currenTheme = 'dark-driver';
    } else {
      this.currenTheme = 'dark-driver';
    }
    const body = document.getElementsByTagName('body')[0];
    body.classList.add(this.currenTheme);
  }

}
